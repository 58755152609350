import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { promoShape } from '../promo/shapes';
import Hero from '../promo/Hero';
import PageHeader from '../generic/PageHeader';
import HeroCollection7Promos from '../promo/collections/HeroCollection7Promos';
import TabBar from '../generic/tabBar/TabBar';
import Tab from '../generic/tabBar/Tab';
import FeaturedHeroCollection from '../promo/collections/FeaturedHeroCollection';
import StandardCollection from '../promo/collections/StandardCollection';
import StandardCollection12Promos from '../promo/collections/StandardCollection12Promos';
import StandardCollection6Promos from '../promo/collections/StandardCollection6Promos';
import ContentSeparator from '../generic/ContentSeparator';
import MetaProperties from '../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

const moduleCollectionTypeMap = {
  'standard-collection': StandardCollection,
  'standard-collection-12-promos': StandardCollection12Promos,
  'standard-collection-6-promos': StandardCollection6Promos,
  'featured-hero-collection': FeaturedHeroCollection,
  'hero-collection-7-promos': HeroCollection7Promos,
};

const renderPromoCollection = (promoCollections, hero) =>
  promoCollections.map((collection, i) => {
    const CollectionType = moduleCollectionTypeMap[collection.moduleType];

    return (
      <Fragment key={`${collection.moduleType}-${collection.title}`}>
        <CollectionType
          id={collection.id}
          title={collection.title}
          description={collection.description}
          promos={collection.promos}
          maxCollectionSize={collection.maxCollectionSize}
          TitleElement={!hero && i === 0 ? 'h1' : undefined}
        />
        <ContentSeparator />
      </Fragment>
    );
  });

export const Page = ({
  hero,
  metaDescription,
  promoCollections,
  title,
  headerTitle,
  entity,
  azEntities,
}) => (
  <div className="index-page">
    <MetaProperties title={title} description={metaDescription} />
    {headerTitle && <PageHeader className="index__title" title={headerTitle} />}
    {azEntities.includes(entity) && (
      <TabBar>
        <Tab
          id="featured"
          href={`/food/${entity}#featured-content`}
          entity={entity}
          title={`Featured ${entity}`}
          selected
        >
          Featured
        </Tab>
        <Tab
          id="all"
          href={`/food/${entity}/a-z#featured-content`}
          entity={entity}
          title={`All ${entity}`}
        >
          All
        </Tab>
      </TabBar>
    )}
    {hero && <Hero {...hero} />}
    {renderPromoCollection(promoCollections, hero)}
  </div>
);

Page.propTypes = {
  hero: PropTypes.shape(promoShape),
  metaDescription: PropTypes.string.isRequired,
  promoCollections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      moduleType: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
      maxCollectionSize: PropTypes.number,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  headerTitle: PropTypes.string,
  entity: PropTypes.string,
  azEntities: PropTypes.arrayOf(PropTypes.string),
};

Page.defaultProps = {
  hero: undefined,
  headerTitle: undefined,
  entity: '',
  azEntities: [],
};

export default connect(state => ({
  ...pick(state.indexPageReducer, [
    'hero',
    'metaDescription',
    'promoCollections',
    'title',
    'headerTitle',
    'entity',
  ]),
  azEntities: state.pageReducer.azEntities,
}))(Page);
